<!-- @Author: Yu_Bo -->
<template>
	<div class='add_edit'>
		<!-- 返回 -->
    <div class="back_btn">
      <div class="icon_btn" @click="backBtn">
        <i class="el-icon-back"></i>
        <span>返回</span>
      </div>
    </div>
    <!--  -->
    <div class="title_info">
      <div class="title">{{title}}</div>
      <div class="input">
        <span class="dian">*</span>
        <span class="name">角色名称:</span>
        <el-input v-model="name" placeholder="请输入角色名称"></el-input>
      </div>
      <div class="btn">
        <div class="left">
          <span class="dian">*</span>
          <span class="name">配置权限:</span>
        </div>
        <div class="right">
          <el-checkbox v-model="allChecked" @change='allChangeBtn(list,$event)'>选择全部</el-checkbox>
          <el-button class="btnBgColor_blue" size="small" type="primary" :loading='loading' @click="preserveBtn">保存</el-button>
        </div>
      </div>
      <!--  -->
      <div class="info_data">
        <div class="data_title">
          <span class="span_one">一级</span>
          <span class="span_two">二级</span>
          <span>三级</span>
        </div>
        <div class="data_list" v-for="(item,index) in list" :key="index">
          <div class="div_one">
            <el-checkbox v-model="item.show" @change="oneChangeBtn(index,$event)">{{item.resname}}</el-checkbox>
          </div>
          <div class="div_two">
            <div class="two_list" v-if="!item.children || item.children.length==0">
              <div class="left"></div>
              <div class="right"></div>
            </div>
            <div class="two_list" v-for="(ite,idx) in item.children" :key="idx">
              <div class="left">
                <el-checkbox v-model="ite.show" @change="twoChangeBtn(index,idx,$event)">{{ite.resname}}</el-checkbox>
              </div>
              <div class="right">
                <div class="right_text" v-for="(ie,ix) in ite.children" :key="ix">
                  <el-checkbox v-model="ie.show" @change="threeChangeBtn(index,idx,ix,$event)">{{ie.resname}}</el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        title:'',
        id:'',
        loading:false,
        // 名称
        name:"",
        // 全选
        allChecked:false,
        // 权限树型结构
        list:[],
        // 已分配的权限
        roleList:[],
			}
		},
		computed: {},
		watch: {},
		created() {
      if(this.$route.query.id){
        this.title='编辑'
        this.name=this.$route.query.name
        this.id=this.$route.query.id
      }else{
        this.title='新增'
        this.id=''
      }
      // 权限树型结构
      this.menuList()
    },
		mounted() {},
		methods: {
      // 权限树型结构
      menuList(){
        var that = this
        that.$personApi.getMenu().then(res => {
          if (res.code == 1000) {
            that.list=res.result
            if(that.id){
              that.getRoleData()
            }else{
              that.permissionTree(that.list)
            }
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 角色已分配权限
      getRoleData(){
        var that = this
        var params = {
          id:that.id
        }
        that.$personApi.roleGetMenus(params).then(res => {
          if (res.code == 1000) {
            // that.roleList=res.result
            that.permissionTree(that.list,res.result)
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 给权限结构添加show
      permissionTree(arr,str=[]){
        var that = this
        arr.forEach(item=>{
          if(that.id){
            var obj = str.filter(v=>v==item.id)
            if(obj.length){
              item.show=true
            }else{
              item.show=false
            }
          }else{
            item.show=false
          }
          if(item.children && item.children.length){
            that.permissionTree(item.children,str)
          }
        })
        that.roleList=[]
        that.allChecked=true
        that.changeId(that.list)
        this.$forceUpdate()
      },
      // 返回
      backBtn(){
        this.$router.back()
      },
      // 保存
      preserveBtn(){
        var that = this
        if(that.name==''){
          this.$errMsg('请输入角色名称')
          return
        }else if(this.roleList.length==0){
          this.$errMsg('请分配该角色的权限')
          return
        }else if(that.id){
          // 编辑
          that.loading = true
          var params = {
            role_name: that.name,
            role_ids: that.roleList.join(','),
          }
          that.$personApi.putRole(params,that.id).then(res => {
            that.loading = false
            if (res.code == 1000) {
              that.backBtn()
              that.$succMsg(res.message)
            } else {
              that.$errMsg(res.message)
            }
          })
        }else{
          // 添加
          that.loading = true
          var params = {
            role_name: that.name,
            role_ids: that.roleList.join(','),
          }
          that.$personApi.postRole(params,that.id).then(res => {
            that.loading = false
            if (res.code == 1000) {
              that.backBtn()
              that.$succMsg(res.message)
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
      // 获取选中的id
      changeId(arr){
        arr.forEach(item=>{
          if(item.show){
            this.roleList.push(item.id)
          }else{
            this.allChecked=false
          }
          if(item.children && item.children.length){
            this.changeId(item.children)
          }
        })
      },
      // 选择全部
      allChangeBtn(list,val){
        list.forEach(item=>{
          item.show=val
          if(item.children && item.children.length){
            this.allChangeBtn(item.children,val)
          }
        })
        this.roleList=[]
        this.allChecked=true
        this.changeId(this.list)
      },
      // 选择一级
      oneChangeBtn(index_x,val){
        if(this.list[index_x].children && this.list[index_x].children.length){
          this.allChangeBtn(this.list[index_x].children,val)
        }
        this.changeBtn(this.list,index_x,'','',1)
      },
      // 选择二级
      twoChangeBtn(index_x,idx_x,val){
        if(this.list[index_x].children[idx_x].children && this.list[index_x].children[idx_x].children.length){
          this.allChangeBtn(this.list[index_x].children[idx_x].children,val)
        }
        this.changeBtn(this.list[index_x].children,index_x,idx_x,'',2)
      },
      // 选择三级
      threeChangeBtn(index,idx,ix,val){
        this.changeBtn(this.list[index].children[idx].children,index,idx,ix,3)
      },
      // 反向选择
      changeBtn(list,index,idx,ix,type){
        var arr = list.filter(item=>item.show)
        if(arr.length){
          if(type==2){
            this.list[index].show=true
          }
          if(type==3){
            this.list[index].children[idx].show=true
            this.changeBtn(this.list[index].children,index,idx,'',2)
          }
        }else{
          if(type==2){
            this.list[index].show=false
          }
          if(type==3){
            this.list[index].children[idx].show=false
            this.changeBtn(this.list[index].children,index,idx,'',2)
          }
        }
        this.$forceUpdate()
        this.roleList=[]
        this.allChecked=true
        this.changeId(this.list)
      }
    },
	}
</script>

<style lang='scss' scoped>
	.add_edit{
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    .back_btn{
      width: 100%;
      padding:18px 20px;
      border-bottom: 1px solid #EDEDED;
      display: flex;
      align-items: center;
      .icon_btn{
        display: flex;
        align-items: center;
      }
      .el-icon-back{
        cursor: pointer;
        font-size: 18px;
        color: #666;
        margin-top: 2px;
      }
      span{
        cursor: pointer;
        padding-left: 6px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .title_info{
      width: 100%;
      padding: 20px 28px;
      .title{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .input{
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        .dian{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #DA1616;
        }
        .name{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
          margin: 0 20px 0 5px;
        }
        .el-input{
          width: 224px;
        }
      }
      .btn{
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
          display: flex;
          align-items: center;
          .dian{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #DA1616;
          }
          .name{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333;
            margin: 0 20px 0 5px;
          }
        }
        .right{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .el-button{
            margin-left: 30px;
          }
        }
      }
      .info_data{
        width: 100%;
        margin-top: 15px;
        border: 1px solid #E9E9E9;
        border-radius: 2px;
        .data_title{
          width: 100%;
          height: 50px;
          background: #F0F2F5;
          display: flex;
          span{
            display: block;
            padding: 0 20px;
            line-height: 50px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            border-right: 1px solid #EDEDED;
          }
          span:last-child{
            border-right: none;
          }
          .span_one{
            width: 150px;
          }
          .span_two{
            width: 200px;
          }
        }
        .data_list{
          width: 100%;
          display: flex;
          .div_one{
            width: 150px;
            padding: 0 20px;
            border-right: 1px solid #EDEDED;
            border-bottom: 1px solid #EDEDED;
            display: flex;
            align-items: center;
          }
          .div_two{
            width: calc(100% - 150px);
            .two_list{
              display: flex;
              .left{
                width: 200px;
                padding: 0 20px;
                border-right: 1px solid #EDEDED;
                border-bottom: 1px solid #EDEDED;
                display: flex;
                align-items: center;
              }
              .right{
                width: calc(100% - 200px);
                min-height: 60px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                border-bottom: 1px solid #EDEDED;
                .right_text{
                  padding-right: 20px;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
        .data_list:last-child{
          .div_one{
            border-bottom: none;
          }
          .div_two:last-child{
            .two_list:last-child{
              .left{
                border-bottom: none;
              }
              .right{
                border-bottom: none;
              }
            }
          }
        }
      }
    }
	}
</style>
